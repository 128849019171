import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const pageNotFound = () => (
    <div className="body">
        <Layout>
            <SEO title="404" />
            <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
                <section className="section">
                    <div className="page-heading-parent">
                        <h1>404.<em></em></h1>
                        <div className="line grey"></div>
                    </div>
                    <h2 className="heading-2">The page you&#x27;re looking for doesn&#x27;t exist! Try these instead:</h2>
                    <div className="button-parent page">
                        <Button name="About" to="/about" />
                        <Button name="Archive" to="/issues" />
                    </div>
                </section>
            </div>
        </Layout>
    </div>
)
export default pageNotFound